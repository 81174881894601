import React from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';

export default class Contact extends React.Component { 

  constructor () {
    super();
    this.state = {
      socialMenu: false
    };
    
}

  componentDidMount () {
    setTimeout(() => {this.setState({socialMenu: true})}, 3000);
  }

  render () {
    
    const handlerSocialMenu = () => {
      this.setState({socialMenu: !this.state.socialMenu})
      
    } 
    
    return (
      <div>
        <div id='contact'>
          <a class="whatsapp-link" href="https://web.whatsapp.com/send?phone=5554984272754&text=Olá+vim+através +do+site&app_absent=0" target="_blank" rel="noreferrer">
            <i class="fa fa-whatsapp teste8878" style={{marginTop: 16, fontSize: 30, marginRight:8}}></i>
          </a>
          <div className='container'>
            <div className='col-md-8'>
              <div className='row'>
                <div className='section-title'>
                  <h2>VENHA NOS VISITAR</h2>
                </div>
                <iframe class="map-frame" title="Localização TBR Pavimentações" width="100%" height="360" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Santiago%20RS,%20Rua%20Manoel%20Rodrigues%20Franco,%20n%C2%BA%20158+(TBR%20PAVIMENTA%C3%87%C3%95ES)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><a href="https://www.mapsdirections.info/en/measure-map-radius/">Radius distance map</a>
              </div>
            </div>
            <div className='col-md-3 col-md-offset-1 contact-info'>
              <div className='contact-item'>
                <h3>Informação de contato</h3>
                <p>
                  <span>
                    <i className='fa fa-map-marker'></i> Endereço
                  </span>
                  {this.props.data ? this.props.data.address : 'loading'}
                </p>
              </div>
              <div className='contact-item'>
                <p>
                  <span>
                    <i className='fa fa-phone'></i> Phone
                  </span>{' '}
                  {this.props.data ? this.props.data.phone.map((d, i) => (
                            <li key={`${d}-${i}`}>{d}</li>
                          )) : 'loading'}
                </p>
              </div>
              <div className='contact-item'>
                <p>
                  <span>
                    <i className='fa fa-envelope-o'></i> Email
                  </span>{' '}
                  {this.props.data ? this.props.data.email.map((d, i) => (
                            <li key={`${d}-${i}`}>{d}</li>
                          )) : 'loading'}
                </p>
              </div>
            </div>
            <div className='col-md-12'>
              <div className='row'>
                <div className='social'>
      
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id='footer'>
          <div className='container text-center'>
            <p>CNPJ 35.998.029/0001-99</p>
            <p>
              &copy; 2021 TBR PAVIMENTACÕES
            </p>
            
          </div>
        </div>
        <BottomNavigation className='mobile' style={{ width: '100%',
          position: 'fixed',  bottom: 0, height: '32px'}}
            showLabels
          >
          <BottomNavigationAction style={{
            background: '#3b5998',
            color: '#1E7A46'
            }} icon={<i class="fa fa-facebook"/>}
            onClick={() => window.open('http://www.fb.com')}
            />
          <BottomNavigationAction style={{
            background: '#E1306C',
            color: '#1E7A46'
            }} icon={<i class="fa fa-instagram"/>} 
            onClick={() => window.open('https://www.instagram.com/tbr_pavimentacoes/')}
            />
          <BottomNavigationAction style={{
            background: '#25d366',
            color: '#1E7A46'
            }} icon={<i class="fa fa-whatsapp"/>} 
            onClick={() => window.open('whatsapp://send?phone=5554984272754?phone=&text=Olá+vim+através+do+site')}
            />
        </BottomNavigation>
        <Grid direction='column' container  xs={1} className='teste751'>
        <Fade in={this.state.socialMenu}>
        <Grid direction='column' container  xs={1} >
          <button style={{
            background: '#25d366',
            color: '#1E7A46'
            }} aria-label="close" >
            <i class="fa fa-whatsapp"
            onClick={() => window.open('https://web.whatsapp.com/send?phone=5554984272754&text=Olá+vim+através +do+site&app_absent=0')}
            />

          </button>
          
          <button style={{
            background: '#E1306C',
            color: '#1E7A46'
            }} aria-label="close" >
            <i class="fa fa-instagram"
            onClick={() => window.open('https://www.instagram.com/tbr_pavimentacoes/')}
            />
          </button>
          <button style={{
            
            background: '#3b5998',
            color: '#1E7A46'
            }} aria-label="close" >
            <i class="fa fa-facebook"
            onClick={() => window.open('http://www.fb.com')}
            />
          </button>
        </Grid>
        </Fade>
          {this.state.socialMenu ?  <i id='iconID' class="fa fa-reply controler" style={{paddingLeft: 12}} onClick={handlerSocialMenu} ></i> : <i class="fa fa-share controler" style={{paddingLeft: 12}} onClick={handlerSocialMenu} ></i> }
        </Grid>

      </div>
    )
  }
}
