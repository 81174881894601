import { Grid } from '@material-ui/core';
import Carousel, { autoplayPlugin,  arrowsPlugin } from '@brainhubeu/react-carousel';import '@brainhubeu/react-carousel/lib/style.css';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

export const ServiceModal = (props) => {
  return (
    <div id='services-modal' className='text-center' >
      <Grid item xs={12} sm={12} container justify='center' >
        <Grid item xs={12} sm={10}>
        <Carousel 
        plugins={[
          'infinite',
        {
          resolve: autoplayPlugin,
          options: {
            interval: 6000,
          }
        },
        {
          resolve: arrowsPlugin,
          options: {
            arrowLeft: <ChevronLeftIcon fontSize='large'  />,
            arrowRight: <ChevronRightIcon fontSize='large' />,
            addArrowClickHandler: true,
          }
        }
        ]}   
      animationSpeed={1000}>
        {props.product.slideImgs.map((d, i) => (
          <img src={d.img}  alt='' class="img-responsive" max-height='100px' />
        ))}
      </Carousel>    
        </Grid>
      </Grid>
      
      <Grid item xs={12} sm={12} container justify='center' >
        <Grid item xs={12} sm={8}>
          <h3>{props.product.name}</h3>
          <br/>
          <div>
            {
              props.product.text.split("\n").map((i,key) => {
                return <p key={key}>{i}</p>;
              })
            }
          </div>
        </Grid>
      </Grid>

     
    </div>
  )

}

export default ServiceModal;