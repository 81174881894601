
export const GalleryModal = (props) => {

  return (
    <div >
        <img src={props.imgSrc} alt='' class="img-responsive"></img>
    </div>
  )

}

export default GalleryModal;