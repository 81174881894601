export const About = (props) => {
  return (
    <div id='about'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-6'>
            {' '}
            <img src='img/tbr_pav/institucionais/institucional2.jpg' className='img-responsive' alt='' />{' '}
          </div>
          <div className='col-xs-12 col-md-6'>
            <div className='about-text'>
              <h2>Porque escolher-nos?</h2>
              <p>{props.data ? props.data.paragraph : 'loading...'}</p>
              <div className='list-style'>
                <div className='col-lg-6 col-sm-6 col-xs-12'>
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : 'loading'}
                  </ul>
                </div>
                <div className='col-lg-6 col-sm-6 col-xs-12'>
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : 'loading'}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{marginTop:82}} className='text-center'>
        {
          window.screen.availWidth <= 500 
          ?              
          <button className='gradient-button' onClick={() => window.open('whatsapp://send?phone=5554984272754?phone=&text=Olá+vim+através+do+site')}>
            <span>Solicite um orçamento</span>
          </button>
          :
          <button className='gradient-button' onClick={() => window.open('https://web.whatsapp.com/send?phone=5554984272754&text=Olá+vim+através+do+site&app_absent=0')}>
            <span>Solicite um orçamento</span>
          </button>
        }
      </div>
    </div>
    
  )
}
