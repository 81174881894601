import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import GalleryModal from './gallery_modal'
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Grid } from '@material-ui/core';
import Gallery1 from "react-grid-gallery";


export default class Gallery extends React.Component { 
  
  constructor () {
      super();

      this.state = {
        galleryImage: '',
        modalIsOpen: false,
        selectedImage: '',
        teste: false,
        selectedAlbum: []

      };
      
  }


  componentDidMount () {
    //this.setState({IMAGES: this.props.data})
  }

  render () {
      
    return (
      <div id='portfolio' className='text-center'>
        <div className='container'>
          <div className='section-title'>
            <h2>Galeria</h2>
            <p>
              Cases de sucesso que contaram com a TBR Pavimentações.
            </p>
          </div>
          {this.props.data ?
          <Gallery1
          images={this.props.data}
          enableLightbox={true}
          // maxRows={3}
          backdropClosesModal
          onClickThumbnail={(context, target) => {this.setState({selectedAlbum: this.props.data[context].slideImgs}, () => {this.setState({teste: !this.state.teste})})}}
          enableImageSelection={false}
          isOpen={this.state.teste}

        />  : 'loading'}
          {
            this.state.teste ?
            this.state.selectedAlbum ?
              <Gallery1
              images={this.state.selectedAlbum}
              enableLightbox={true}
              lightboxWillClose={() => {this.setState({teste: !this.state.teste})}}
              rowHeight={0}
              isOpen={true}

            />  : 'loading'
            : ''
          }
    
          <Dialog  fullWidth={true}  aria-labelledby="max-width-dialog-title" open={this.state.modalIsOpen}>
            <Grid container justify="space-between">
              <DialogTitle id="customized-dialog-title">
                Lorem Ipsum
              </DialogTitle>
              <IconButton aria-label="close" onClick={()=>{this.setState({modalIsOpen: false})}}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <GalleryModal imgSrc={this.state.galleryImage}/>
            <h5>Material</h5>
            <p>Lorem Ipsum</p>
            <h5>data</h5>
            <p>11/09/2001</p>
          </Dialog>
        </div>
        <div style={{marginTop:82}}>
          {
            window.screen.availWidth <= 500 
            ?              
            <button className='gradient-button' onClick={() => window.open('whatsapp://send?phone=5554984272754?phone=&text=Olá+vim+através+do+site')}>
              <span>Solicite um orçamento</span>
            </button>
            :
            <button className='gradient-button' onClick={() => window.open('https://web.whatsapp.com/send?phone=5554984272754&text=Olá+vim+através+do+site&app_absent=0')}>
              <span>Solicite um orçamento</span>
            </button>
          }
        </div>
      </div>
    );
  }
};
