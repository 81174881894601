export const Header = (props) => {
  return (
    <header id='header'>
      <div className='intro'>
        <div className='intro-text'>
          
        </div>
      </div>
    </header>
  )
}
