import React from 'react';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Grid } from '@material-ui/core';
import ServiceModal from './service_modal'

export default class Services extends React.Component {
  
  constructor () {
      super();

      this.state = {
        productImage: '',
        modalIsOpen: false,
        product: []
      };
      
  }


  componentDidMount () {
    
  }

  render () {

    return (
      <div id='services' className='text-center'>
        <div className='container'>
          <div className='section-title'>
            <h2>Nossos Produtos</h2>
            <p>
              Click nos produtos ou entre em contato para mais informações
            </p>
          </div>
          <div className='row'>
            {this.props.data
              ? this.props.data.map((d, i) => (
                  <div key={`${d.name}-${i}`} className='col-md-4'>
                    {' '} 
                    <Button onClick={()=>{this.setState({productImage: d.img, product: d}, () => {this.setState({modalIsOpen: true})})}}>
                      <img src={d.img} alt='' width="200" height="160"></img>
                    </Button> 
                    <div className='service-desc'>
                      <h3>{d.name}</h3>
                      <br/>
                    </div>
                  </div>
                ))
              : 'loading'}
          </div>
          <Dialog  fullWidth={true} maxWidth={'sm'} aria-labelledby="max-width-dialog-title" open={this.state.modalIsOpen}>
            <Grid container justify="flex-end">
              <IconButton aria-label="close" onClick={()=>{this.setState({modalIsOpen: false})}}>
                <CloseIcon fontSize='large'/>
              </IconButton>
            
            </Grid>
            
            <ServiceModal imgSrc={this.state.productImage} product={this.state.product} />
          
          </Dialog>
        </div>
        <div style={{marginTop:42}}>
          {
            window.screen.availWidth <= 500 
            ?              
            <button className='gradient-button' onClick={() => window.open('whatsapp://send?phone=5554984272754?phone=&text=Olá+vim+através+do+site')}>
              <span>Solicite um orçamento</span>
            </button>
            :
            <button className='gradient-button' onClick={() => window.open('https://web.whatsapp.com/send?phone=5554984272754&text=Olá+vim+através+do+site&app_absent=0')}>
              <span>Solicite um orçamento</span>
            </button>
          }
        </div>
        
        
      </div>
    );
  }
};
